@font-face {
    font-family: 'Gameplay';
    src: local('Gameplay'), url(./fonts/Gameplay.ttf) format('truetype');
}

@font-face {
    font-family: 'GameOver';
    src: local('game_over'), url(./fonts/game_over.ttf) format('truetype');
}

@font-face {
    font-family: 'ArcadeClassic';
    src: local('arcadeclassic'), url(./fonts/arcadeclassic.ttf) format('truetype');
}

@font-face {
    font-family: 'Pixel';
    src: local('LLPIXEL3'), url(./fonts/LLPIXEL3.ttf) format('truetype');
}

@font-face {
    font-family: 'EHSMB';
    src: local('EHSMB'), url(./fonts/EHSMB.ttf) format('truetype');
}

@font-face {
    font-family: 'CFLCD';
    src: local('CFLCD-Regular'), url(./fonts/CFLCD-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'LCD';
    src: local('LCDM2B'), url(./fonts/LCDMonoWinTT/LCDM2B__.TTF) format('truetype');
}

@font-face {
    font-family: 'LCD-digital';
    src: local('DS-DIGI'), url(./fonts/digital/DS-DIGI.TTF) format('truetype');
}

.font-1 {
    font-family: EHSMB;
}

.font-2 {
    font-family: LCD-digital;
}

.glossaryButton {
    text-decoration: underline;
}

.glossaryButton:hover {
    color: #262E83;
}

/* Styles pour la barre de défilement */
::-webkit-scrollbar {
  width: 8px; /* Largeur de la barre de défilement */
}

/* Styles pour le "thumb" (la poignée de la barre de défilement) */
::-webkit-scrollbar-thumb {
  background-color: #48565d; /* Couleur du "thumb" */
  border-radius: 6px; /* Bord arrondi du "thumb" */
}

/* Styles pour le "track" (le fond de la barre de défilement) */
::-webkit-scrollbar-track {
  background-color: #13181f; /* Couleur du "track" */
}

.skillsbutton {
    display: inline-block;
    transition: transform 0.3s ease; /* Animation de transition pour un effet en douceur */
}
  
.skillsbutton:hover {
    transform: scale(1.2); /* Augmente la taille de 20% au survol */
}